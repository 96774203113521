import React, { useState } from 'react';
import { Button, Grid, Link, TextField, Typography, Alert, MenuItem } from '@mui/material';
import { WhiteBox } from './WhiteBox';
import { environments, msTeamsChannelForCreationNotifications, manualProcessPlaybookLink, zapierHookUrl } from '../config';
import { FAQ } from './FAQ';

type State = { type: 'initial' } | { type: 'submitting' } | { type: 'submitted' } | { type: 'error'; message: string };

const isProd = process.env.NODE_ENV === 'production' || window.location.hash === '#behaveLikeProd';

const testingEnvironmentNote =
  'This will still create an incident. But to prevent confusion with real incidents the info message will not be posted to the "General" channel. The channel "Incident Management Testing Channel" will be used instead.';

export const Report: React.FC<{ user: string; internalMode: boolean }> = (props) => {
  const [state, setState] = useState<State>(() => ({ type: 'initial' }));

  const [description, setDescription] = useState(() => new URLSearchParams(window.location.search).get('description') ?? '');
  const [descriptionValidationError, setDescriptionValidationError] = useState<string>();
  const [details, setDetails] = useState(() => new URLSearchParams(window.location.search).get('details') ?? '');
  const [environment, setEnvironment] = useState(() => getInitialEnvironment(props.internalMode));
  const reporter = props.user;

  const createIncident = async () => {
    if (!description) {
      setDescriptionValidationError('A description is required to create an incident.');
      return;
    }

    setState({ type: 'submitting' });

    const body = JSON.stringify({
      description,
      details,
      reporter,
      environment,
      source: isProd ? 'incident creation page' : 'incident creation page (development)',
    });
    console.log('Zapier URL: ' + zapierHookUrl);
    console.log('Payload: ' + body);
    try {
      await fetch(zapierHookUrl, { method: 'POST', body });
      setState({ type: 'submitted' });
    } catch (e: any) {
      setState({ type: 'error', message: e.message });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Alert severity="info">
          Please be reachable in the next minutes for any further inquires of the Incident Commander. Probably they need more details.
        </Alert>
      </Grid>
      {!isProd && (
        <Grid item>
          <Alert severity="warning">
            You are currently using the <b>development</b> environment, when submitting the form the Zap will not actually create an incident, It will just stop
            after the first step. In case you'd like to test this in real,{' '}
            <Link
              onClick={() => {
                window.location.assign('#behaveLikeProd');
                window.location.reload();
              }}
            >
              click here
            </Link>
            .
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <WhiteBox spacing={3}>
          <Grid item>
            <Typography variant="h5">Create the incident</Typography>
            {state.type === 'submitted' && (
              <Alert color="success">
                The incident has been created. Follow{' '}
                <Link href={msTeamsChannelForCreationNotifications} target="_blank">
                  MS Teams
                </Link>{' '}
                to continue. If nothing happens in the next 60 seconds, then please fallback to the <Link href={manualProcessPlaybookLink}>manual process</Link>
                .
              </Alert>
            )}
            {state.type === 'error' && (
              <Alert color="error">
                Failed to create the incident: {state.message}
                <br />
                Please try again, or fall back to the <Link href={manualProcessPlaybookLink}>manual process</Link>.
              </Alert>
            )}
          </Grid>
          {state.type !== 'submitted' && (
            <>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Affected Environment"
                  helperText={environment === 'test' ? testingEnvironmentNote : undefined}
                  value={environment}
                  onChange={(e) => setEnvironment(e.target.value)}
                  fullWidth
                >
                  {environments.map((env) => (
                    <MenuItem key={env.id} value={env.id}>
                      {env.niceName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Short Description (required)"
                  InputLabelProps={{ shrink: true }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onBlur={() => setDescriptionValidationError(undefined)}
                  error={!!descriptionValidationError}
                  helperText={descriptionValidationError}
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Details (optional)"
                  InputLabelProps={{ shrink: true }}
                  value={details}
                  rows={2}
                  onChange={(e) => setDetails(e.target.value)}
                  helperText="For example an error message that is shown"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Reporter" value={reporter} fullWidth disabled />
              </Grid>
              <Grid item xs={12}>
                {zapierHookUrl.includes('3ya0z4i') && (
                  <Typography variant="caption" style={{ float: 'right' }}>
                    Local dev, the process will not be triggered.
                  </Typography>
                )}
                <Button variant="contained" color="primary" onClick={() => createIncident()} disabled={state.type === 'submitting'}>
                  Create Incident
                </Button>
              </Grid>
            </>
          )}
        </WhiteBox>
      </Grid>
      <FAQ />
    </Grid>
  );
};

const getInitialEnvironment = (internalMode: boolean) => {
  if (internalMode) return 'mp-internal';
  const envParam = new URLSearchParams(window.location.search).get('environment');
  if (envParam) return envParam;
  return 'mp-prod';
};
