import React from 'react';
import { Button, Grid, Link, Typography, Alert } from '@mui/material';

import { useMicrosoftCurrentUser } from '../hooks/useMicrosoftCurrentUser';
import { Report } from './Report';
import { authentication } from '../utils/authenticate';
import { WhiteBox } from './WhiteBox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  app: {
    backgroundColor: theme.palette.grey[300],
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 10px',
    fontSize: '12px',
  },
  content: {
    maxWidth: '600px',
  },
}));

export const App: React.FC<{ loggedIn: boolean }> = (props) => {
  const user = useMicrosoftCurrentUser(props.loggedIn);

  const internalMode = window.location.href.includes('/test') || window.location.href.includes('/staging') || window.location.href.includes('/internal');

  const styles = useStyles();
  return (
    <>
      <div className={styles.classes.app}>
        <Grid container spacing={2} direction="column" className={styles.classes.content}>
          <Grid item>
            <Typography variant="h4">
              <Link
                href="https://itdesign-de.atlassian.net/wiki/spaces/IM/overview"
                style={{ color: styles.theme.palette.primary.main, textDecoration: 'none' }}
              >
                Meisterplan Incident Management
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            {user ? (
              <Report user={user.userPrincipalName} internalMode={internalMode} />
            ) : (
              <WhiteBox>
                <Grid item>
                  <Alert severity="info" style={{ marginBottom: 10 }}>
                    To continue, you need to sign-in with your Microsoft account.
                  </Alert>
                  <Button variant="contained" color="primary" onClick={() => authentication.login()}>
                    Login with Microsoft Account
                  </Button>
                </Grid>
              </WhiteBox>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
