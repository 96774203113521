export interface IncidentEnvironment {
  id: string;
  niceName: string;
}

export const zapierHookUrl = 'https://hooks.zapier.com/hooks/catch/1868813/3f7pja0';
export const msTeamsChannelForCreationNotifications =
  'https://teams.microsoft.com/l/channel/19%3a6bdd3d101ac9401baa34005a63e80508%40thread.tacv2/General?groupId=bddba621-e9e9-43b8-a9ad-d7c4630ef7ff&tenantId=59d8fb6f-d8ec-45fc-bf5d-df045f927658';

export const manualProcessPlaybookLink = 'https://itdesign-de.atlassian.net/wiki/spaces/IM/pages/20743394828/Start+an+incident+manually';

export const environments: IncidentEnvironment[] = [
  { id: 'mp-prod', niceName: 'Meisterplan: Production (eu/us.meisterplan.com)' },
  { id: 'mp-internal', niceName: 'Meisterplan: Internal (Staging, Dev Tools, ...)' },
  { id: 'test', niceName: 'Incident Management: Process Testing' },
];
