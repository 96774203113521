import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export class MicrosoftAuthenticationProvider {
  private msalConfig: Configuration = {
    auth: {
      clientId: 'e188da58-9ca3-4caa-b512-6ddfd67654f9',
      redirectUri: window.location.href.replace(/\??.*/, ''),
      authority: 'https://login.microsoftonline.com/itdesign.de',
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  private scopes = ['User.Read', 'offline_access'];
  private application = new PublicClientApplication(this.msalConfig);

  public authenticate = async (): Promise<boolean> => {
    await this.application.handleRedirectPromise();
    if (window.location.hash.startsWith('#code=')) {
      return true;
    } else {
      return await this.isAuthenticated();
    }
  };

  public login = () => {
    this.application.acquireTokenRedirect({ scopes: this.scopes, account: this.application.getAllAccounts()[0] });
  };

  public isAuthenticated = async (): Promise<boolean> => {
    try {
      await this.application.acquireTokenSilent({
        account: this.application.getAllAccounts()[0],
        scopes: this.scopes,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  public getAccessToken = async (): Promise<string> => {
    try {
      const result = await this.application.acquireTokenSilent({
        account: this.application.getAllAccounts()[0],
        scopes: this.scopes,
      });
      return result.accessToken;
    } catch (e) {
      throw new Error('Failed to get access token, try sign-in again.');
    }
  };

  public logout = async () => {
    await this.application.logoutRedirect();
  };
}

export const authentication = new MicrosoftAuthenticationProvider();
