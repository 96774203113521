import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { App } from './components/App';
import { theme } from './utils/theme';
import { authentication } from './utils/authenticate';

(async () => {
  const loggedIn = await authentication.authenticate();

  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App loggedIn={loggedIn} />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
