import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { msTeamsChannelForCreationNotifications } from '../config';
import React from 'react';

export const FAQ: React.FC = () => (
  <>
    <Grid item>
      <Typography variant="h6">FAQs</Typography>
    </Grid>
    <Grid item>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Who should create incidents?</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Everyone who detects a severe problem with our systems is encouraged to create an incident.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>When should I create an incident?</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ask yourself this question: Is it only a minor issue or are multiple customers/prospects hindered from working? In case you are unsure: Handle it as
            an incident!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>What happens after I click "Create Incident"?</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your incident will be handled asap. The system does automatically ensure your report will be handled by the right people.
            <ol>
              <li>
                <b>Communication channel is established</b>
                <br />
                The system does automatically create a MS Teams channel to streamline the communication. A link to the channel is posted{' '}
                <Link href={msTeamsChannelForCreationNotifications} target="_blank" rel="noopener">
                  here
                </Link>
                .
              </li>
              <li>
                <b>Incident Commander is assigned</b>
                <br />
                The system will automatically assign an incident commander. The commander is paged and will coordinate the incidence response.
              </li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>How does the process work in detail?</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You'll find more details about the incident management process in{' '}
            <Link href="https://itdesign-de.atlassian.net/wiki/spaces/IM/overview" target="_blank" rel="noopener">
              Confluence
            </Link>
            .
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </>
);
