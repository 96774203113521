import { useEffect, useState } from 'react';

import { authentication } from '../utils/authenticate';
import { Client as MSGraphClient } from '@microsoft/microsoft-graph-client';

const client = MSGraphClient.initWithMiddleware({
  authProvider: { getAccessToken: authentication.getAccessToken },
});

interface MicrosoftUser {
  userPrincipalName: string;
}

export const useMicrosoftCurrentUser = (loggedIn: boolean): MicrosoftUser | undefined => {
  const [user, setUser] = useState<MicrosoftUser>();

  useEffect(() => {
    if (!loggedIn) return;
    (async () => {
      const msUser = (await client.api('/me').get()) as MicrosoftUser;
      setUser(msUser);
    })();
  }, [loggedIn, setUser]);

  return user;
};
