import * as React from 'react';

import { Grid, Paper } from '@mui/material';

export const WhiteBox: React.FC<React.PropsWithChildren & { spacing?: number }> = (props) => {
  return (
    <Grid item>
      <Paper style={{ padding: 16 }}>
        <Grid container spacing={props.spacing ?? 2} direction="column">
          {props.children}
        </Grid>
      </Paper>
    </Grid>
  );
};
